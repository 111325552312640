@import "variables.scss";
@import "forms.scss";
@import "slick.scss";
/*
|--------------------------------------------------------------------------
| Base Variables
|--------------------------------------------------------------------------
*/
$font-family-base: "Open Sans", sans-serif;
$font-family-text-base: "Merriweather", sans-serif;
$font-family-lead-base: "Work Sans", sans-serif; // Custom

// Font Weight Base
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;

// Body
$bg-gray: #303030;
$body-color: $black !default;

body {
  font-family: $font-family-base;
  color: $black;
  font-size: $font-size-sm;
  line-height: 1.57;
}

main {
  overflow: hidden;
}

a {
  color: $black;
  transition: 0.5s;
  text-decoration: none;

  &:hover {
    color: $black;
  }
}

label a {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

p {
  font-size: 17px;

  &.smaller {
    font-size: 14px;
  }
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1 {
  font-family: $font-family-text-base;
  font-size: $h1-font-size;

  &.h2 {
    font-size: $h2-font-size;
    font-weight: 400;
    margin-bottom: 25px;
  }
}

h2 {
  font-size: 40px;
  font-family: $font-family-text-base;
  font-weight: 300;
  margin-bottom: 25px;
  line-height: 30px;

  &.h1 {
    font-size: 40px;
    font-family: $font-family-text-base;
  }
}

h3 {
  font-size: $h3-font-size;
  font-family: $font-family-text-base;
  font-weight: 400;

  &.h2 {
    font-size: $h2-font-size;
    font-weight: 400;
    margin-bottom: 25px;
    margin-top: 30px;
  }

  &:first-child {
    margin-top: 0;
  }
}

h4 {
  font-size: $h4-font-size;
  font-family: $font-family-text-base;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 30px;
}

h5 {
  font-size: $h5-font-size;
  font-weight: $font-weight-bold;
  color: $primary-color;
  margin-top: 30px;
  margin-bottom: 15px;
}

h6 {
  font-size: $h6-font-size;
  font-family: $font-family-text-base;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 600;
}

.container.container-big {
  max-width: 100%;
}

.btn:not(:disabled),
.btn {
  color: white;
  border: none;
  padding: 11px;
  font-size: 24px;
  font-weight: 400;
  background-color: $primary-color;
  min-width: 200px;
  text-align: center;
  transition: 0.6s;
  letter-spacing: 1px;
  border-radius: 30px;
  &:not(.disabled):active,
  &:active,
  &:hover,
  &:focus {
    color: white;
    background-color: #8e4a19;
    outline: none;
    box-shadow: none;
  }

  &.btn-small {
    min-width: 110px;
    font-size: 11px;
  }

  &.btn-medium {
    min-width: 130px;
    font-size: 13px;
    font-weight: bold;
  }

  &.btn-two-lines {
    line-height: 1.2;
    padding: 10px 15px;
  }

  &.btn-white {
    background-color: white;
    color: black;
    border: 1px solid $primary-color;
    width: 100%;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  &.btn-gray {
    background-color: $nonary-grey;
    border: 2px solid $primary-color;
    color: white;

    &:hover {
      background-color: $primary-color;
    }
  }

  &.btn-black {
    background-color: black;
    border: none;
    color: white;

    &:hover {
      background-color: $primary-color;
    }
  }
}

.zoom {
  overflow: hidden;

  &:hover img {
    transform: scale(1.04);
  }

  img {
    width: 100%;
    transition: 0.6s;
  }
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

p {
  transition: 0.6s;
}

// < nav
header {
  background-color: $bg-gray;
  height: $header-height;
  padding: 0px 8px;

  .nav-img {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    img {
      max-height: 146px;
    }
  }

  .navbar {
    padding: 35px 30px 35px 30px;
  }

  .nav-header {
    max-width: 300px;
    margin: auto;

    a {
      color: $primary-color;
    }
  }

  .searchbar {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible .search-input {
      caret-color: $primary-color !important;
    }
  }

  .search-input {
    caret-color: $primary-color;
  }

  .nav-components {
    display: flex;
    flex-direction: column;
  }

  .bag {
    a {
      display: inline-block;
      height: 26px;
      position: relative;
      margin-right: 12px;

      span {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        background-color: $primary-color;
        font-size: 10px;
        top: -7px;
        right: -12px;
        width: 19px;
        height: 19px;
        padding: 2px 7px 2px 0px;
      }

      img {
        height: 100%;
      }
    }

    margin-top: auto;
    margin-bottom: 18px;
  }

  h3 {
    color: $primary-color;
    position: relative;
    top: 12px;
  }

  .nav-items {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-block;
    transition: 0.5s;
    border-bottom: 3px solid transparent;
    &.active,
    &:hover {
      border-bottom: 3px solid $primary-color;
    }

    a {
      font-size: 24px;
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  #burger-container {
    position: relative;
    margin-left: auto;
    margin-right: 0px;

    button {
      width: auto;
      display: block;
      background: 0 0;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      margin-right: 0px;
      cursor: pointer;

      span {
        background: #be8950;
        display: block;
        width: 50px;
        height: 5px;
        margin-bottom: 5px;
        position: relative;
        top: 0;
        -webkit-transition: all ease-in-out 0.4s;
        -o-transition: all ease-in-out 0.4s;
        transition: all ease-in-out 0.4s;
      }
    }
  }

  .menu-container.open {
    height: calc(100vh - calc(100% + 3px));

    .searchbar {
      display: block;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible .search-input {
        caret-color: $primary-color;
      }
    }

    ul {
      display: block;
    }
  }

  #burger-container.open {
    span:nth-child(1) {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 15px;
    }

    span:nth-child(2),
    span:nth-child(3) {
      width: 0;
      opacity: 0;
    }

    span:nth-child(4) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -15px;
    }
  }

  .searchbar {
    position: relative;
    top: 12px;
  }
}

// > nav
// < banner-top
.banner-top {
  max-height: calc(100vh - #{$header-height-margin});
  position: relative;
  overflow: hidden;

  .banner-img {
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: #e7e7e7;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .banner-bg {
    position: absolute;
    height: 100%;
    width: 100vw;
    z-index: -1;
    top: 0px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .banner-content {
    z-index: 1;
    position: absolute;
    height: 100%;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 4%;
    padding-right: 4%;
  }

  .banner-left {
    p {
      color: $quinary-grey;
      margin-bottom: 25px;
    }
  }

  h1 {
    color: black;
    max-width: 330px;
    margin-bottom: 10px;

    span {
      font-size: 31px;
      font-family: $font-family-base;
    }
  }

  .banner-right {
    height: 100%;
    max-height: 500px;

    .banner-right-wrapper:hover .banner-element .bg {
      animation: 0;
    }

    .banner-right-wrapper:hover .banner-element p {
      animation: 0;
    }

    p {
      color: black;
    }
  }

  .banner-right-wrapper {
    max-width: 354px;
    margin-right: 0px;
    margin-left: auto;
  }

  .banner-element {
    height: 25%;
    width: 100%;
    position: relative;
    padding-left: 60px;
    font-size: 17px;
    font-weight: 600;

    & p {
      animation: fontColor 20s infinite;
      animation-delay: 0.5s;
      // transition: all 5s ease-in;
    }

    &:nth-of-type(2) p {
      animation-delay: 5.5s;
    }

    &:nth-of-type(3) p {
      animation-delay: 10.5s;
    }

    &:nth-of-type(4) p {
      animation-delay: 15.5s;
    }

    & .bg {
      animation: changeColor 20s infinite;
      animation-delay: 0.5s;
      // transition: all 5s ease-in;
    }

    &:nth-of-type(2) .bg {
      animation-delay: 5.5s;
    }

    &:nth-of-type(3) .bg {
      animation-delay: 10.5s;
    }

    &:nth-of-type(4) .bg {
      animation-delay: 15.5s;
    }

    &:hover {
      .bg {
        animation: 0;
      }

      p {
        color: white;
      }

      .bg {
        background-color: $primary-color;
      }
    }

    @keyframes fontColor {
      0% {
        color: white;
      }

      25% {
        color: white;
      }

      26% {
        color: black;
      }

      100% {
        color: black;
      }
    }

    @keyframes changeColor {
      0% {
        background-color: $primary-color;
      }

      25% {
        background-color: $primary-color;
      }

      26% {
        background: transparent;
      }

      100% {
        background: transparent;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 100vw;
      height: 1px;
      background-color: $quaternary-grey;
      left: 20px;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0px;
      width: 100vw;
      height: 1px;
      background-color: $quaternary-grey;
      left: 20px;
      z-index: -1;
    }

    a {
      height: 100%;
      width: 100%;
      display: flex;
    }

    // &.active {
    //   p {
    //     color: white;
    //   }

    //   .bg {
    //     background-color: $primary-color;
    //   }
    // }

    p {
      margin: auto 0px auto auto;
      z-index: 1;
      transition: 0s;
    }

    .bg {
      position: absolute;
      left: 0px;
      width: 100vw;
      height: 100%;
      clip-path: polygon(20px 0, 100% 0, 100% 100%, 20px 100%, 0 50%);
    }
  }

  .bg:hover ~ .p {
    color: red;
  }

  h2.h1 {
    font-size: $h1-font-size;
    margin-bottom: 3rem;
    margin-top: 0;
  }
}

// > banner-top
// < gallery-m
.item {
  a:hover {
    color: $primary-color;
  }
}

.type {
  font-size: 12px;
  color: $senary-grey;
  font-weight: bold;

  a {
    color: $senary-grey;
  }

  a:hover {
    color: $primary-color;
  }
}

.price {
  color: $primary-color;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}

.gallery-m {
  text-align: center;

  .item {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    min-height: 53.4px;
  }

  .gallery-m-wrapper {
    border: 1px solid $quaternary-grey;
  }

  a:hover {
    color: $primary-color;
  }

  .gallery-m-img {
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
    }

    &:hover .btn {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .btn {
      opacity: 0;
      transition: 0.6s;
      top: 39%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
}

// > gallery-m
// < gallery-s
.gallery-s {
  .gallery-s-wrapper {
    border: 1px solid $quaternary-grey;
    width: 49.5%;

    &:last-of-type {
      background-color: $octonary-grey;
    }

    .price {
      font-size: 14px;
    }

    .gallery-s-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  p {
    margin-bottom: 8px;
  }

  img {
    height: 100%;
  }
}

// > gallery-s
// < about
.about {
  background-color: $nonary-grey;
  text-align: center;
  padding: 50px 0px 60px 0px;
  margin-bottom: 35px;

  h2 {
    color: $primary-color;
    position: relative;
    margin-bottom: 45px;

    &::after {
      position: absolute;
      content: "";
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 21px;
      background-color: $primary-color;
    }
  }

  p {
    color: white;
    margin: auto;
    width: 49%;
    margin-bottom: 17px;
  }

  a.btn {
    text-transform: uppercase;
  }
}

// > about
// < slick-slider
.slider {
  font-weight: bold;
  border-top: 1px solid $octonary-grey;
  border-bottom: 1px solid $octonary-grey;
  padding-top: 20px;
  padding-bottom: 40px;

  .hidden {
    .btn {
      color: $senary-grey;
    }
  }

  .slick-slider {
    text-align: center;
    margin-top: 65px;

    .slick-product {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 15%;
        width: 70%;
        height: 1px;
        background-color: $octonary-grey;
        transition: 0.3s;
      }

      &:hover::after {
        background-color: $primary-color;
      }

      div {
        height: 250px;
        padding-bottom: 42px;

        a {
          height: 100%;

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }

    .type {
      margin-bottom: 10px;

      a {
        color: $primary-color;
        text-transform: uppercase;

        &:hover {
          color: $senary-grey;
        }
      }
    }

    .item {
      margin-bottom: 30px;
      min-height: 53.4px;
    }

    &.hidden {
      display: none;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    .slick-slide {
      // oko
      padding: 0px 45px;
      //
    }
  }

  .arrow-prev {
    left: 0px;
  }

  .news-btn {
    .btn {
      border-left: 3px solid;
      border-right: 1px solid black;
    }
  }

  .sales-btn {
    .btn {
      border-left: 2px solid black;
      border-right: 3px solid;
    }
  }

  .btn {
    background-color: transparent;
    color: black;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
    padding: 0 14px 0 14px;
    min-width: fit-content;
    // oko
    line-height: 20px;

    &.scroll-btn {
      font-size: 14px;
      color: $senary-grey;
      border: none;
      min-width: fit-content;
      padding: 0px;

      &:hover {
        color: $senary-grey;
      }

      &:first-of-type {
        margin-left: 30px;
      }

      &:last-of-type {
        margin-right: 30px;
      }
    }

    //
    &:not(.disabled):active,
    &:active,
    &:hover,
    &:focus {
      background-color: transparent;
      color: black;
    }
  }

  .slick-arrow {
    position: absolute;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 25px;
    width: 13px;
    top: -51px;
  }

  .slick-prev {
    left: 0px;
    background-image: url(../img/slick-left.png);
    background-color: transparent;

    &::after {
      content: "wcześniejsze";
      font-size: 14px;
      color: $senary-grey;
      font-weight: bold;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      text-transform: uppercase;
    }
  }

  .slick-next {
    right: 0px;
    background-image: url(../img/slick-right.png);
    background-color: white;

    &::after {
      content: "następne";
      font-size: 14px;
      color: $senary-grey;
      font-weight: bold;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      text-transform: uppercase;
    }
  }
}

// > slick slider
// < contact
.contact-item {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 20px;

  div:nth-child(1) {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(2) {
    margin: 10px 0px 10px 0px;
    font-size: 20px;
    font-weight: 700;
  }

  div:nth-child(3) {
    a {
      font-size: 24px;
    }
  }
}

.contact-item:nth-of-type(1) {
  div:nth-child(3) {
    font-weight: 600;
  }
}

.contact-item:nth-of-type(2) {
  div:nth-child(3) {
    width: 150px;
    margin: auto;
    line-height: 15px;
    p,
    a {
      font-size: 14px;
      color: $quinary-grey;
    }
  }
}

.contact-item:nth-of-type(3) {
  div:nth-child(3) {
    a {
      color: $primary-color;
      font-size: 14px;
    }
  }
}

// > contact
// < footer
footer {
  background-color: #2e2e2e;
  padding: 30px 0px 20px 0px;
  color: $primary-white;
  font-size: 13px;
  margin-top: 30px;

  .footer-links {
    display: flex;
    justify-content: space-between;

    a {
      color: $senary-grey;
      transition: 0.6s;
      text-decoration: transparent;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// > footer
// < gallery-xl
.gallery-xl {
  padding-bottom: 65px;

  .btn-wrapper {
    text-align: center;

    &.hidden {
      display: none;
    }

    .btn {
      width: calc(33.3% - 11px);
    }
  }

  a {
    height: 100%;
    width: 100%;
  }
}

.grid {
  .grid-title {
    height: 80px;
    align-items: center;
  }

  .grid-item {
    width: calc(33.3% - 11px);
    float: left;
    // height: 680px;

    a {
      display: inline-block;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .zoom {
    margin-bottom: 16px;
    position: relative;

    &.hidden {
      display: none;
    }

    .gallery-xl-price {
      position: absolute;
      top: 7px;
      left: 7px;
      background-color: white;
      padding: 1% 2.9%;
      z-index: 4;
      transition: 0.3s;
      color: gray;

      p {
        font-size: 14px;
        font-weight: 600;
        color: grey;
        margin: 0px;
        text-transform: uppercase;
      }
    }

    .gallery-xl-describe {
      position: absolute;
      left: 7px;
      background-color: white;
      z-index: 4;
      color: grey;
      font-weight: 600;
      transition: 0.3s;
      bottom: 7px;
      padding: 1% 8%;
      padding-right: 11%;
      padding-left: 4%;

      span {
        font-size: 17px;
      }
    }
  }
}

.gallery-xl-wrapper:nth-child(2) {
  flex-basis: 36%;
  margin-top: 1%;
}

// > gallery-xl
// < gallery-l
.page-nav {
  font-weight: 600;

  .page-nav-filters {
    display: flex;
    justify-content: end;
  }

  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  option {
    font-weight: 400;
    color: black;

    &:hover {
      cursor: pointer;
    }
  }

  .form-select {
    display: inline-block;
    width: 100px;
    border: none;
    max-width: 75px;
    padding-right: 0px;
    color: $primary-color;
    font-weight: 600;
    background-position: right;

    &:focus,
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    option {
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }

  .size {
    max-width: 110px;
  }

  a {
    color: $primary-color;
    transition: 0.6s;
    font-weight: 600;

    &:hover {
      color: black;
    }
  }
}

.gallery-l {
  .gallery-l-wrapper {
    .zoom {
      width: 95%;
      margin-left: auto;

      img {
        width: 100%;
      }
    }
  }

  .item {
    font-weight: 700;
    min-height: 0px;
    font-size: 21px;
    margin-bottom: 0px;
  }

  .price {
    text-transform: uppercase;
  }

  .gallery-l-icon {
    margin: 12px 0px;
    width: 85%;
    margin-right: auto;
    position: relative;

    &.opacity {
      opacity: 0.5;
    }

    label {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .form-check {
    display: inline-block;
    padding: 0px;
    margin-right: 10px;
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .form-check-input {
    width: 0%;
    height: 0%;
    opacity: 0;
  }

  input:checked ~ label {
    background-color: black;
    border: black;
    color: white;
  }

  .form-check {
    label {
      border: 1px solid $primary-white;
      color: $primary-white;
      display: inline-block;
      width: 35px;
      text-align: center;
      line-height: 35px;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .add-card {
    .btn {
      width: 100%;
      line-height: 45px;
      font-size: 16px;
      text-transform: uppercase;
      margin: 45px 0px 35px;
    }
  }

  .accordion {
    .accordion-item {
      border: none;
      border-bottom: 1px solid $primary-color;
    }

    button {
      font-family: $font-family-base;
      background-color: white;
      color: black;
      font-weight: 600;
      font-size: 13px;
      text-decoration: none;
      text-transform: uppercase;
      box-shadow: none;
      padding-left: 0px;
    }

    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }

  .zoom {
    input {
      width: 0px;
      opacity: 0;
      height: 0px;
      position: absolute;
      visibility: hidden;
    }

    input.gallery-selector:checked + .gallery-l-img {
      display: block;
      width: 100%;
    }

    .gallery-l-img {
      display: none;
      object-fit: cover;
    }
  }

  .swiper-pagination {
    display: none;
  }

  .accordion-body {
    padding-top: 0;
  }
}

@media (max-width: 1700px) {
  .gallery-l {
    .gallery-l-wrapper {
      .zoom {
        width: 92%;
      }
    }
  }
}

@media (max-width: 1500px) {
  .gallery-l {
    .gallery-l-wrapper {
      .zoom {
        width: 90%;
      }
    }
  }
}

// > gallery-l
@media (max-width: 1399.98px) {
  .banner-top {
    .banner-right-wrapper {
      max-width: 324px;
    }

    .banner-element {
      padding-left: 58px;
    }
  }
}

@media (max-width: 1199.98px) {
  h1 {
    font-size: 50px;
  }

  .banner-top {
    p {
      font-size: 15px;
    }

    .banner-right-wrapper {
      max-width: 265px;
    }

    .banner-element {
      padding-left: 42px;
    }
  }

  // .banner-top .banner-element .bg {
  //     clip-path: none;
  // }
  .about {
    p {
      width: 70%;
    }
  }

  .grid {
    .grid-title {
      height: 60px;
    }
  }
}

@media (max-width: 991.98px) {
  header {
    padding: 0px;
  }

  .page-nav {
    font-size: 11px;

    p {
      font-size: 11px;
    }

    .select_mate {
      min-width: 56px;

      .icon_select_mate {
        font-size: 0px;
        height: 20px;
      }
    }

    .form-select {
      font-size: 11px;
      max-width: 50px;
      padding-left: 0px;

      option {
        font-size: 11px;
      }
    }

    .size {
      max-width: 65px;
    }
  }

  .gallery-l {
    .gallery-l-wrapper {
      .zoom {
        width: 100%;
      }
    }
  }

  .banner-top {
    height: 100vh;
    max-height: none;

    .banner-content {
      padding: 0px;
    }

    .banner-img {
      height: calc(100vh - 223px);

      img {
        object-fit: cover;
        object-position: center;

        max-width: none;
      }
    }

    .banner-bg {
      height: calc(100vh - 224px);
    }

    .banner-right-wrapper {
      max-width: none;
    }

    .banner-element {
      width: 50%;
      max-width: none;
      padding: 0px;
      height: 113px;
      background-image: url(/assets/img/main-banner-bg.png);

      &:nth-of-type(2n + 1) {
        border-right: 1px solid $quaternary-grey;
      }

      &:nth-of-type(2n) {
        border-left: 1px solid $quaternary-grey;

        .bg {
          left: -1px;
        }
      }

      p {
        margin: auto auto;
        max-width: 80%;
        text-align: center;
      }

      .bg {
        clip-path: none;
        width: calc(100% + 1px);
        z-index: 1;
      }

      &::after {
        z-index: 0;
      }
    }
  }

  .banner-left {
    height: calc(100vh - 224px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 4%;
  }

  .gallery-m {
    .item {
      min-height: 62.8px;
    }
  }

  .item {
    font-size: 20px;
  }

  .type {
    font-size: 16px;
  }

  .price {
    font-size: 24px;
  }

  .gallery-s {
    .gallery-s-wrapper {
      text-align: center;
      width: 50%;

      .item {
        min-height: 62.8px;
        margin-bottom: 4px;
      }

      .price {
        font-size: 17px;
      }

      .gallery-s-img {
        height: 111px;
      }
    }

    p {
      margin-bottom: 5px;
    }
  }

  .gallery-xl {
    .btn-wrapper {
      .btn {
        font-size: 17px;
      }
    }
  }

  .slider {
    .slick-slider {
      margin-top: 100px;
    }
  }

  .gallery-l {
    .gallery-l-photo {
      order: -1;
    }
  }

  footer {
    padding-top: 20px;

    .footer-links {
      justify-content: flex-start;
      align-items: center;

      a {
        margin-right: 10px;
      }
    }
  }

  .copyrights {
    order: 1;
  }
}

@media (max-width: 767.98px) {
  header {
    height: $header-height-mobile;
    border-bottom: 3px solid #be8950;

    nav {
      height: 100%;

      & > div {
        height: 100%;
      }
    }

    .nav-img {
      height: 100%;
    }

    .nav-logo {
      height: 87%;

      img {
        height: 100%;
        max-height: initial;
      }
    }

    #burger-container {
      margin-left: initial;
    }

    .nav-components {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      flex-direction: row-reverse;
    }

    .searchbar {
      position: absolute;
      display: none;
      right: 0px;
    }

    .bag {
      margin-bottom: auto;
      margin-top: auto;
      margin-right: 22px;
    }

    .menu-container {
      width: 100%;
      position: absolute;
      top: calc(100% + 3px);
      right: 0;
      background: #303030;
      z-index: 1000;

      ul {
        display: none;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          padding-right: 0;
          display: flex;
          transition: 0.6s;
          flex-wrap: wrap;
          border-bottom: 1px solid #6d6d6d;

          &:hover {
            background-color: $bg-gray-hover;
          }

          a {
            width: 100%;
            padding-right: 15px;
            font-size: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: right;
            display: block;
            line-height: 1.1;
            font-weight: 300;

            color: #e9e9e7;
          }
        }

        li:last-of-type a {
          color: $primary-color;
        }
      }
    }
  }

  .banner-top {
    height: calc(100vh + 134px);

    .banner-img {
      height: calc(100vh - #{$header-height-mobile-margin});
    }

    .banner-bg {
      height: calc(100vh - #{$header-height-mobile-margin});
    }

    .banner-left {
      height: calc(100vh - #{$header-height-mobile-margin});
    }
  }

  .grid {
    .grid-item {
      width: calc(50% - 8px);
    }
  }

  .gallery-xl {
    .btn-wrapper {
      .btn {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .banner-top {
    height: calc(100vh + 360px);
    .banner-element {
      width: 100%;
    }
  }

  .item {
    min-height: 78.5px;
    font-size: 25px;
  }

  .type {
    font-size: 20px;
  }

  .price {
    font-size: 30px;
  }

  .gallery-xl-price p {
    font-size: 12px;
  }

  .grid .zoom .gallery-xl-describe {
    padding: 0 4% 3px 4%;
    line-height: 17px;

    span {
      font-size: 13px;
    }
  }

  .gallery-s {
    .gallery-s-wrapper {
      width: 100%;
    }
  }

  .gallery-m .gallery-m-img .btn {
    opacity: 1;
  }

  .about {
    p {
      width: 76%;
    }
  }

  .gallery-l {
    .item {
      margin-bottom: 0px;
    }

    .zoom {
      .gallery-l-img {
        display: block;
        height: 69vh;
      }
    }

    .add-card {
      .btn {
        margin-top: 5px;
      }
    }

    .form-check {
      margin-top: 10px;
    }
  }

  .footer-links {
    font-size: 11px;
  }

  .page-nav {
    display: flex;

    .page-nav-wrapper {
      width: 99vw;
    }

    .page-nav-left {
      max-width: 47%;
      padding-right: 0px;
    }

    .page-nav-filters {
      max-width: 53%;
      padding: 0px;
      // padding-right: 5px;
    }
  }

  .gallery-l {
    .swiper-pagination {
      .swiper-pagination-bullet {
        background-color: $primary-color;
        width: 11px;
        height: 11px;
      }

      display: block;
    }
  }

  .grid {
    .grid-item {
      width: 100%;
    }
  }
}

@media (max-width: 370px) {
  .footer-links {
    flex-basis: 100%;
    margin-bottom: 16px;
  }
}

@media (min-width: 768px) {
  header {
    display: flex;
    align-items: center;

    .menu-container {
      position: static;

      ul {
        text-align: center;
      }

      .searchbar {
        display: block;
      }
    }

    .nav-menu {
      // max-width: 550px;
      margin: auto;
      margin-top: 36px;
    }

    #burger-container button {
      display: none;
    }

    .menu-toggle {
      display: none;
    }
  }
}

@media (min-width: 1640px) {
  .container.container-big {
    max-width: 1610px;
  }

  .banner-top {
    .banner-element {
      padding-left: 54px;
      max-width: none;
    }

    .banner-content {
      padding-left: 0;
      padding-right: 0;
    }

    h1 {
      font-size: 110px;
      max-width: 500px;

      span {
        font-size: 45px;
      }
    }
  }

  .banner-left {
    p {
      font-size: 23px;
    }
  }
}

@media (min-width: 992px) {
  .banner-right-wrapper {
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .gallery-s {
    p {
      line-height: 16px;
    }
  }
}
