.searchbar {
  height: 48px;
  background-color: $tertiary-grey;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
}

.search-input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: calc(100% - 34px);
  // caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
  margin-left: 0px;
  float: left;
  transform: translate(15px, -7px);


}

.searchbar:hover>.search-input {

  // caret-color: red;
  transition: width 0.4s linear;
}

.searchbar:hover>.search-icon {
  color: #e74c3c;
}

.search-icon {
  margin-top: 2px;
  margin-right: 9px;
  width: 24px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;

  img {
    filter: invert(32%) sepia(19%) saturate(2%) hue-rotate(322deg) brightness(99%) contrast(92%);
  }
}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
  .searchbar {
    width: 270px;
    margin-left: auto;

  }
}

@media (max-width: 575.98px) {}

@media(min-width:768px) and (max-width: 991.98px) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
  }

  :-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
  }
}


.cont_heg_50 {
  position: absolute;
  height: 50%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fd7b52;
}


.icon_select_mate {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  font-size: 16px;
  height: 22px;
  transition: all 275ms;
}

.select_mate {
  position: relative;
  float: left;
  color: $primary-color;
  font-weight: 300;
  background-color: #fff;
  border-radius: 3px;
  min-width: 90px;
  transition: all 375ms ease-in-out;
  text-align: start;

  /* Oculto el elemento select */
  select {
    position: absolute;
    overflow: hidden;
    height: 0px;
    opacity: 0;
    z-index: -1;
  }
}

.cont_list_select_mate {
  position: relative;
  float: left;
  width: 100%;
}

.cont_select_int {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 999;
  overflow: hidden;
  height: 0px;
  width: 100%;
  background-color: #fff;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
  transition: all 375ms ease-in-out;

  li {
    position: relative;
    float: left;
    width: 96%;
    border-bottom: 1px solid #E0E0E0;
    background-color: white;
    list-style-type: none;
    padding: 10px 2%;
    margin: 0px;
    transition: all 275ms ease-in-out;
    display: block;
    cursor: pointer;

    &:last-child {
      border-radius: 3px;
      border-bottom: 0px;

    }

    &:hover {
      background-color: #EEEEEE;
    }
  }

  .active {
    background-color: #EEEEEE;
  }

}


.selected_option {
  padding: 0px 2%;
  width: 96%;
  display: block;
  margin: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}